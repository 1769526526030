body {
  background-color: #ffaf4e4b;
}

.weight-calculator-container {
  width: 330px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.plate-info-outer-card {
  height: 180px;
  width: 270px;
}

.bar {
  position: absolute;
  background: #393d41;
  width: 230px;
  left: 0px;
  height: 8px;
  border-radius: 2px;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bar-plate-part {
  position: absolute;
  background: #262728;
  width: 200px;
  left: 50px;
  height: 12px;
  border-radius: 2px;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bar-nub {
  position: absolute;
  background: #464c52;
  width: 8px;
  height: 19px;
  left: 50px;
  border: 1px solid #808d9b;
  border-radius: 3px;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bar-weight {
  position: absolute;
  top: 0px;
  right: 7px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 40%;
}

.plate {
  position: absolute;
  background: grey;
  width: 25px;
  border: 1px solid white;
  border-radius: 6px;
  color: white;
  text-align: center;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.plate-set-label {
  position: absolute;
  top: 0px;
  left: 10px;
}
